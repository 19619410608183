import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/components/global/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/components/global/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/components/global/RealUserMonitoring.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/components/global/WebVitals.tsx");
;
import(/* webpackMode: "eager" */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Marianne/static/Marianne-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Bold.woff2\",\"weight\":\"700\"}],\"fallback\":[\"arial\"]}],\"variableName\":\"marianne\"}");
;
import(/* webpackMode: "eager" */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/styles/typography.css");
